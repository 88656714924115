.HeaderCenter {
  margin: 96px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.HeaderCenter .inner {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HeaderCenter .inner h1 {
  margin-bottom: 8px;
}

.HeaderCenter .inner h4 {
  margin-bottom: 32px;
  max-width: 400px;
}

@media only screen and (max-width: 1000px) {
  .HeaderCenter {
    margin-top: 0;
    margin-bottom: 48px;
  }
}
