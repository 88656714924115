.Header {
  background: var(--color-secondary);
  padding: 48px 0;
}

@media only screen and (max-width: 1000px) {
  .Header {
    padding: 16px 0;
  }
}
