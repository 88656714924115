@import url(https://fonts.googleapis.com/css2?family=Karla&family=Overpass+Mono:wght@600&display=swap);
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-family: "Karla", sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-size: 18px;
}

:root {
  --color-primary: #5144e4;
  --color-secondary: rgb(233, 233, 233);
  --font-body: "Karla";
  --font-heading: "Overpass Mono";
}

h1,
h2,
h3,
h4 {
  font-family: "Overpass Mono";
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.2em;
}

h3 {
  font-size: 1.6em;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 1000px) {
  body {
    font-size: 16px;
  }

  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1em;
  }
}


.Header {
  background: var(--color-secondary);
  padding: 48px 0;
}

@media only screen and (max-width: 1000px) {
  .Header {
    padding: 16px 0;
  }
}

.Navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 96px;
}

.Navbar img {
  height: 25px;
}

.Navbar ul {
  list-style: none;
  display: flex;
  flex-direction: row;
}

.Navbar ul li a {
  font-size: 20px;
}

@media only screen and (max-width: 1000px) {
  .Navbar img {
    height: 20px;
  }

  .Navbar ul li a {
    font-size: 16px;
  }
}

.Spacer {
  width: 70%;
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
  .Spacer {
    width: 100%;
    padding: 0 24px;
    max-width: unset;
  }
}

.HeaderCenter {
  margin: 96px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.HeaderCenter .inner {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HeaderCenter .inner h1 {
  margin-bottom: 8px;
}

.HeaderCenter .inner h4 {
  margin-bottom: 32px;
  max-width: 400px;
}

@media only screen and (max-width: 1000px) {
  .HeaderCenter {
    margin-top: 0;
    margin-bottom: 48px;
  }
}

.Button {
  padding: 8px 24px;
  padding-bottom: 5px; /* Accounts for weird font */
  border-radius: 6px;
  font-size: 0.9em;
  font-family: var(--font-heading);
  cursor: pointer;
  border: 3px solid red;
  background: red;
  white-space: nowrap;
}

.Button.primary {
  background: var(--color-primary);
  border: 3px solid var(--color-primary);
  color: white;
}

.Button.secondary {
  background: none;
  border: 3px solid var(--color-primary);
}

.ButtonGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.ButtonGroup .Button {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .ButtonGroup {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.Content {
  padding: 128px 0;
}

.Content h2:first-child {
  margin-top: 0;
}

.Content h2 {
  margin-top: 64px;
  margin-bottom: 8px;
}

.Content h3 {
  margin-top: 48px;
  margin-bottom: 4px;
}

.Content h4 {
  margin-top: 48px;
  margin-bottom: 4px;
}

.Content ul {
  margin-top: 4px;
  margin-left: 24px;
}

.Content ul li {
  margin-bottom: 8px;
}

.Content p {
  margin-bottom: 16px;
  line-height: 1.4;
}

.Content img,
.Content video {
  width: 100%;
  margin-bottom: 24px;
}

.Content code {
  background: var(--color-secondary);
  border-radius: 4px;
  padding: 1px 4px;
}

.Content a {
  color: var(--color-primary);
}

.Content pre {
  display: flex;
  flex-direction: column;
  background: var(--color-secondary);
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1000px) {
  .Content {
    padding: 32px 0;
  }
}

.Footer {
  background: var(--color-secondary);
  padding: 24px 0;
}

.Footer * {
  color: rgb(98, 98, 98);
}

.Footer h4 {
  margin-bottom: 8px;
}

.Footer p {
  margin-top: 32px;
}

@media only screen and (max-width: 1000px) {
  .Footer * {
    text-align: center;
  }
}

.GetInTouch {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 128px;
}

.GetInTouch h2 {
  margin-bottom: 4px;
}

.GetInTouch p {
  margin-bottom: 48px;
  max-width: 25ch;
}

.GetInTouch ul.grid {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
}

.GetInTouch ul.grid li img {
  width: 50px;
  margin-bottom: 16px;
}

.GetInTouch ul.grid li p {
  margin-bottom: 8px;
  max-width: unset;
}

.GetInTouch ul.grid li a {
  color: var(--color-primary);
}

@media only screen and (max-width: 1000px) {
  .GetInTouch ul.grid {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .GetInTouch ul.grid {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 32px;
  }
}

